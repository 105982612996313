import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css';

// ReactDOM.render(<App/>, document.getElementById('root'));


//new version
import * as ReactDOMClient from 'react-dom/client';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(<App/>);