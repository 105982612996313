import React, {useState, useEffect} from 'react'

import data from '../../data/products.json'
import { Product } from '../../components';


const Products = () => {

  // const [data, setData] = useState({});
  // useEffect(() => {
  //   (async () => {
  //     try 
  //     {
  //       const response = await fetch(
  //         "https://bqpm2.digi-ants.com/products.json"
  //       );
  //       const parsed = await response.json();
  //       const data =JSON.parse(JSON.stringify(parsed));
  
  //       setData(data);
  //     }
  //     catch (error){
  //       console.log(error);
  //     }
  //   })();
  // }, []);

  return (
    <div className='da__products-container section__padding'>
      {/* <h1 className='gradient__text'>Our Products</h1> */}

      {data.products.map((item,index)=>(
          <Product data={item} key={index}/>
      ))}
    </div>
  )
}

export default Products