import React from 'react';

import {Article, CTA, Feature, Navbar, Brand, Product} from './components';
import {Header, Footer, Products} from './containers';
import './app.css'

const App = () => {
  return (
    <div className='App'>
        <Navbar/>

        <div className='gradient__bg' id="home">
          <Header/>
        </div>
        
        <div id="products">
          <Products />
        </div>

        <div id="contact">
          <Footer />
        </div>   
    </div>
  )
  }

export default App;
