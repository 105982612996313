import React from 'react';
import './product.css';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Feature from '../feature/Feature';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

const Product = ({data}) => {
  const isYoutube =data.hasOwnProperty('youtube_link') &&  !(data.youtube_link==="");
  const isImage =data.hasOwnProperty('image_link') &&  !(data.image_link==="");
  const isLink= data.hasOwnProperty('link') && !(data.link==="");
  const isBothEmpty=(!isYoutube) && (!isImage);

  let media;
  if (isYoutube)
  {
    media=<div className='video-container'><iframe src={data.youtube_link} width="560" height="315" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe></div>;
  }else if (isImage)
  {
    media=<img width="100%" height="100%" src={data.image_link}/>;
  }

  return (
    <div className='da__product-container'>
        <div className='da__product-header__container'>
            <div className='da__product-header__title'>
                <h1 className='gradient__text'>{data.title}</h1>
                <p>{data.description}</p>
                { isLink
                  ?<a href={data.link}  target="_blank">More...</a>
                  :<div></div>
                }
            </div>
            <div className={isBothEmpty?'da__display-none':'da__product-header__media'}>
              {media}
            </div>
        </div>
        <Carousel responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={5000}
          transitionDuration={3000}
          infinite={true}
          keyBoardControl={true}
          >
            {data.features.map((item, index)=>(
              <Feature title={item.title} text={item.description} key={index}/>
            ))}
          
        </Carousel>
    </div>
  )
}

export default Product