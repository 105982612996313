import React from 'react';
import './header.css';
import headerImg from '../../assets/header1.png'

const Header = () => {
  return (
    <div className='da__header-main section__padding'>
      <div className='da__header'>
        <div className='da__header-content'>
          <h1 className='gradient__text'>Automation is Revolutionizing the AEC Industry</h1>
          {/* <div style={{"float":"right", "margin":"1rem"}}>
            <img width={"200px"} src={headerImg} alt='ai'></img>
          </div> */}
          <p>Automation has become increasingly vital in the Architecture, Engineering, and Construction (AEC) industry due to its ability to improve efficiency, save time, increase accuracy, and ensure consistency. By automating repetitive tasks, AEC professionals can focus on more complex and critical activities, resulting in better-quality work and increased profitability. As the industry continues to evolve, the significance of automation will only continue to grow, helping professionals meet the demands of a highly competitive market.</p>
        </div>

        <div className='da__header-image'>
          <img src={headerImg} alt='ai'></img>
        </div>
      </div>
      <p>Digi-Ants is the leading provider of automation solutions for Revit, AutoCAD, and Civil 3D. Our cutting-edge technology and expert team enable us to deliver top-quality automation tools that streamline your design process, save you time, and boost your productivity. With Digi-Ants, you can focus on your core business while we take care of the tedious and time-consuming tasks. Our solutions are designed to meet the needs of architects, engineers, contractors, and designers, and we offer a wide range of products that cater to different levels of automation requirements. Whether you need to automate complex calculations, generate detailed reports, or simplify repetitive tasks, Digi-Ants has the right solution for you.</p>
    </div>

  )
}

export default Header