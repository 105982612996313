import React, {Fragment, useEffect, useState} from 'react'
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import './navbar.css';
import logo from '../../assets/Digi-Ants Logo.png'

const Menu = () => {
  <>
            
  </>
}

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [sticky, setSticky] = useState(false);
  useEffect(()=>{
    const handleScroll = ()=>{
      setSticky(window.scrollY>1);
      // console.log(window.scrollY);
    }
    window.addEventListener('scroll',handleScroll);
    return ()=> window.removeEventListener('scroll',handleScroll);
  })
  return (
    <nav className={sticky?'sticky':''}>
    <div className='da__navbar'>
        <div className='da__navbar-links'>
          <div className='da__navbar-links_logo'>
            <img className='logo' src={logo} alt='logo'></img>
          </div>
          <div className='da__navbar-links_container'>
          <p><a href='#home'>Home</a></p>
            <p><a href='#products'>Products</a></p>
            <p><a href='#contact'>Contact us</a></p>
          </div>
        </div>        
        <div className='da__navbar-menu'>
          {toggleMenu 
          ? <RiCloseLine color='#fff' size={27} onClick={()=> setToggleMenu(false) }/>
          : <RiMenu3Line color='#fff' size={27} onClick={()=> setToggleMenu(true) }/>}

          {toggleMenu && (
          <div className='da__navbar-menu_container scale-up-center'> 
            <div className='da__navbar-menu_container-links'>
            <p><a href='#home'>Home</a></p>
            <p><a href='#products'>Products</a></p>
            <p><a href='#contact'>Contact us</a></p>
            </div>
          </div>  
          )

          }
        </div>
    </div>
    </nav>
  )
}

export default Navbar